import React, { createContext, useCallback, useState } from "react";
import { ConfigType, PropsConfigType } from "./props";
import { useLocalStorage } from "react-use";
import useGTM from "../../hooks/gtm";

const DEFAULT_VALUE = {
  config: {
    hash: process.env.REACT_APP_HASH,
    modalidade: "",
    logo: "",
    qrcode: 0,
    codeGTM: "",
    qtdePlanos: 0,
    primaryColor: "#385fa5",
    secondaryColor: "#FABA1F",
    numeroInstalacao: 0,
    manutencao: false,
    backgroundImage: "",
    banner: "",
    bannerMobile: "",
    pid: "",
    offer_id: "",
    exibirPF: false,
    exibirPJ: false,
    porcentagemFixa: false,
    utm_source: "",
    xtra: "",
    valorMinimoPF: 0,
    valorMinimoPJ: 0,
  },
  update: () => {},
};

const JornadaContext = createContext<PropsConfigType>(DEFAULT_VALUE);

const JornadaContextProvider: React.FC = ({ children }) => {
  const [data, setData] = useLocalStorage<ConfigType>(
    "jornada-store",
    DEFAULT_VALUE.config
  );

  const [integrate, setIntegrate] = useState(false);
  const { init } = useGTM();

  const update = useCallback(
    (settings: ConfigType) => {
      setData(settings);
      if (settings?.codeGTM?.length > 0 && !integrate) {
        setIntegrate(true)
        init({ id: settings.codeGTM });
      }
    },
    [setData, init]
  );

  return (
    //@ts-ignore
    <JornadaContext.Provider value={{ config: data, update }}>
      {children}
    </JornadaContext.Provider>
  );
};

export { JornadaContextProvider };
export default JornadaContext;
