import { createGlobalStyle, css } from "styled-components";
import Agenda from '../assets/fonts/EncodeSansExpanded-ExtraLight.ttf';
import AgendaBold from '../assets/fonts/EncodeSansExpanded-Light.ttf';
import AgendaBoldCondensed from '../assets/fonts/EncodeSansExpanded-Light.ttf';
import AgendaUltraCondensed from '../assets/fonts/EncodeSansExpanded-Bold.ttf';
import AgendaLight from '../assets/fonts/EncodeSansExpanded-ExtraLight.ttf';
import AgendaLightCondensed from '../assets/fonts/EncodeSansExpanded-ExtraLight.ttf';
import AgendaMedium from '../assets/fonts/EncodeSansExpanded-Medium.ttf';
import AgendaItalic from '../assets/fonts/EncodeSansExpanded-Thin.ttf';
import { theme } from 'cleanclic-ui';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Agenda';
    src: url(${Agenda}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Agenda Bold';
    src: url(${AgendaBold}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Agenda Bold Condensed';
    src: url(${AgendaBoldCondensed}) format('truetype');
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Agenda Ultra Condensed';
    src: url(${AgendaUltraCondensed}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Agenda Light';
    src: url(${AgendaLight}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Agenda Light Condensed';
    src: url(${AgendaLightCondensed}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Agenda Medium';
    src: url(${AgendaMedium}) format('truetype');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Agenda Italic';
    src: url(${AgendaItalic}) format('truetype');
    font-style: italic;
    font-display: swap;
  }
  

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }

  html { 
    
  }
  a{
    text-decoration: none;
  }

  body {
    font-family: 'Agenda';
  }

  .Toastify__toast-container{
    z-index: 999999 !important;
  }

  .verifyCode{
    height: 90px;
    font-size: 50px;
    text-align: center;
    width: 90%;
    border: 1px solid ${theme.colors.primaryBlue};
    border-radius: 5px;
  }

  ${({ theme }) => css`
        
  `}
`;

export default GlobalStyle