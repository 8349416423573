import React from 'react'
import { ThemeProvider } from 'styled-components';
import { theme } from 'cleanclic-ui';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from './theme/globalStyle'
import Routes from './routes'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={5000} />
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  );
}

export default App;