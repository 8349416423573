import React, { createContext, useCallback } from "react";
import { UsuarioType, PropsUsuarioType } from "./props";
import { useLocalStorage } from "react-use";

const DEFAULT_VALUE = {
  dados: {
    token: '',
    numInstalacao: '',
    email: '',
    pessoa: {},
  },
  update: () => { },
};

//@ts-ignore
const UsuarioContext = createContext<PropsUsuarioType>(DEFAULT_VALUE);


const UsuarioContextProvider: React.FC = ({ children }) => {
  //@ts-ignore
  const [data, setData] = useLocalStorage<UsuarioType>("usuario-store", DEFAULT_VALUE.dados);

  const update = useCallback((settings: UsuarioType) => {
    setData(settings);
  }, [setData]);

  return (
    <UsuarioContext.Provider value={{ dados: data, update }}>
      {children}
    </UsuarioContext.Provider>
  );
};

export { UsuarioContextProvider };
export default UsuarioContext;