export const isAuthFn = () => {
  try {
    let usuario = localStorage.getItem("usuario-store");
    let auth = JSON.parse(usuario)

    return auth.token !== '';
  } catch (e) {
    return false;
  }
}
