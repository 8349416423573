export const inMaintenance = () => {
  try {
    let jornada = localStorage.getItem("jornada-store");
    let items = JSON.parse(jornada)

    return items.manutencao;
  } catch (e) {
    return false;
  }
}
