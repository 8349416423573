import axios from "axios";
import { toast } from "react-toastify";

let api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use((config) => {
  let usuario = localStorage.getItem("usuario-store");
  let user = JSON.parse(usuario);
  try {
    config.headers["Authorization"] = `Bearer ${user.token}`;
  } catch (e) {}

  return config;
});

api.interceptors.response.use(
  (response) => {
    if (
      (response.status === 200 || response.status === 201) &&
      response.data.message
    ) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (typeof error !== "undefined") {
        if (error.response.data.data) {
          const data = error.response.data;

          if (Object.entries(data.data).length > 1) {
            Object.entries(data.data).forEach(([index, item]) => {
              toast.error(index + ": " + item, { autoClose: false });
            });
          } else {
            Object.entries(data.data).forEach(([index, item]) => {
              toast.error(item, { autoClose: false });
            });
          }
        } else {
          if (error.response.status === 422) {
            const errros = error.response.data.error;
            if (errros) {
              Object.entries(errros).forEach(([key, value]) => {
                toast.error(value[0], { autoClose: false });
              });
            }
          }

          if (error.response.status === 401) {
            toast.error(error.response.data.message, { autoClose: false });
          }

          if (error.response.status === 400) {
            toast.error(error.response.data.message, { autoClose: false });
          }

          if (error.response.status === 500) {            
            if (!error.response.config.url === "https://legado.detronicenergia.com/api/jornada/upload-fatura") {
              toast.error(error.response.data.message, { autoClose: false });
            }
          }

          if (error.response.status === 503) {
            let jornada = localStorage.getItem("jornada-store");
            let obj = JSON.parse(jornada);
            if (obj.manutencao === false) {
              obj.manutencao = true;
              let newJornada = JSON.stringify(obj);
              localStorage.setItem("jornada-store", newJornada);
              window.location.reload();
            }
          }
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
