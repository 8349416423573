import React from "react";

import { JornadaContextProvider } from './jornada/context';  
import { UsuarioContextProvider } from './usuario/context';  

const GlobalContext: React.FC = ({ children }) => {
  return (
    <>
      <JornadaContextProvider> 
        <UsuarioContextProvider>
          {children} 
        </UsuarioContextProvider>
      </JornadaContextProvider>
    </>
  );
};

export default GlobalContext;