import styled from "styled-components";

import { tokens } from "cleanclic-ui";
const { colors } = tokens;

export const Container = styled.div``;
export const Row = styled.div`
  background: ${colors.veryLightGrey};
  width: 100%;
  height: 100vh;
`;
export const Col = styled.div``;

export const Wrapper = styled.div`
  background: ${colors.veryLightGrey};
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
`;