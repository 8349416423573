import { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "@material/react-linear-progress/dist/linear-progress.css";
import LinearProgress from "@material/react-linear-progress";
import { pagesConfig } from "./pages/pagesConfig";
import { isAuthFn } from "./components/ValidateUser";
import { inMaintenance } from "./components/ValidateMaintenance";
import Manutencao from "./pages/Manutencao/index";

const handleRoutes = () => {
  return pagesConfig.map((pageConfig) => {
    if (pageConfig.routes !== undefined) {
      return pageConfig.routes.map((dataRoute: any, keyRoute) => {
        let { path, component } = dataRoute;

        if (pageConfig?.auth !== undefined && pageConfig?.auth === true) {
          if (window.location.pathname === path && !isAuthFn()) {
            window.location.href = '/login';
          }
        }

        return <Route exact={true} path={path} component={component} />;
      });
    }
    return null;
  });
}

const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className=''>
            <LinearProgress buffer={0.9} progress={0.8} />
          </div>
        }
      >
        {
          inMaintenance() ?
            <Switch>
              {<Manutencao />}
            </Switch>
            :
            <Switch>
              {handleRoutes()}
            </Switch>
        }
      </Suspense>
    </Router>
  )
};

export default Routes;
