import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalContext from './context/' 

ReactDOM.render(
  <StrictMode>
    <GlobalContext> 
      <App /> 
    </GlobalContext> 
  </StrictMode>,
  document.getElementById('root')
);