import { useEffect, useState, useContext, Fragment } from "react";
import { Typography } from "cleanclic-ui";
import api from "../../services/api";
import { Wrapper, Container, Row, Col } from "./styled";

import JornadaContext from "../../context/jornada/context";
import bgMaintenance from "../../assets/image/bg_maintenance.png";

const Manutencao = (): JSX.Element => {
  const { config, update } = useContext(JornadaContext);
  const [counter, setCounter] = useState(10);
  const verificarManutencao = () => {
    api
      .get(`https://legado.detronicenergia.com/api/jornada/landingPages/${config.hash}`)
      .then(() => {
        update({ ...config, manutencao: false });
        window.location.reload();
      })
      .catch(() => {
        update({ ...config, manutencao: true });
      });
  };
  useEffect(() => {
    setTimeout(() => {
      if (counter >= 1) {
        setCounter(counter - 1);
      } else {
        verificarManutencao();
        setCounter(10);
      }
    }, 1000);
  }, [verificarManutencao(),counter]);

  return (
    <Fragment>
      <Wrapper>
        <Container as="form" className="container">
          <Row className="row d-flex justify-content-center">
            <Col className="col-12 col-md-7">
              <Row className="row d-flex justify-content-center">
                <Col className="col-12 d-flex justify-content-center">
                  <img src={bgMaintenance} alt="" />
                </Col>
                <Col className="col-12 d-flex justify-content-center text-center">
                  <Typography
                    fontSize={[35]}
                    variant="h2"
                    fontWeight="bolder"
                    color="primaryBlue"
                  >
                    Desculpe o transtorno, mas estamos realizando algumas
                    manutenções no momento. Estaremos online em breve!
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Fragment>
  );
};

export default Manutencao;
