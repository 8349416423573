import { lazy } from "react";

export const JornadaConfig = {
  auth: false,
  routes: [
    {
      path: "/",
      component: lazy(() => import("./Home")),
    },
    {
      path: "/check-hash/:hash",
      component: lazy(() => import("./Home")),
    },
    {
      path: "/selecao-de-modalidade",
      component: lazy(() => import("./SelecaoDeModalidade")),
    },
    {
      path: "/criar-senha/:hash",
      component: lazy(() => import("./PessoaFisica/CriarSenha")),
    },
    {
      path: "/esqueci-senha/:hash",
      component: lazy(() => import("./PessoaFisica/CriarSenha")),
    },
    {
      path: "/Login/:redirect?",
      component: lazy(() => import("./Login")),
    },
  ],
};


export const JornadaConfigAuth= {
  auth: true,
  routes: [
    {
      path: "/tipo-de-contratos",
      component: lazy(() => import("./SelecaoDeModalidade/contrato")),
    },
    {
      path: "/novo-contrato/selecionar-instalacao",
      component: lazy(() => import("./SelecaoDeModalidade/selecionarInstalacao")),
    },
    {
      path: "/pf/dados-iniciais",
      component: lazy(() => import("./PessoaFisica/DadosIniciais/DadosIniciais")),
    },
    {
      path: "/pj/dados-iniciais",
      component: lazy(() => import("./PessoaJuridica/DadosIniciais/DadosIniciaisPj")),
    },
    {
      path: "/dados-sua-conta-luz/:pularDC?",
      component: lazy(() => import("./PessoaFisica/DadosContaLuz")),
    },
    {
      path: "/pf/inscricao-dados-complementares",
      component: lazy(() => import("./PessoaFisica/InscricaoComplementar")),
    },
    {
      path: "/pj/inscricao-dados-complementares",
      component: lazy(() => import("./PessoaJuridica/InscricaoComplementar")),
    },
    {
      path: "/codigo-verificacao",
      component: lazy(() => import("./CodigoVerificacao")),
    },
    {
      path: "/upload-de-fatura/:partnerData?",
      component: lazy(() => import("./UploadFatura")),
    },
    {
      path: "/escolha-seu-plano/:novoContrato?",
      component: lazy(() => import("./EscolhaSeuPlano")),
    },    
  ],
};
