import { lazy } from "react";

export const DashboardConfig = {
  auth: true,
  routes: [
    {
      path: "/dashboard",
      component: lazy(() => import("./MeuPainel")),
    },
    {
      path: "/dashboard/:modalidade/meu-painel",
      component: lazy(() => import("./MeuPainel")),
    },
    {
      path: "/dashboard/minhas-instalacoes",
      component: lazy(() => import("./PessoaFisica/MinhasInstalacoes")),
    },
    {
      path: "/dashboard/PF/meu-perfil",
      component: lazy(() => import("./Perfil/PF")),
    },
    {
      path: "/dashboard/PJ/meu-perfil",
      component: lazy(() => import("./Perfil/PJ")),
    },
    {
      path: "/dashboard/meus-contratos",
      component: lazy(() => import("./MeusContratos")),
    },
   /* {
      path: "/dashboard/minhas-faturas",
      component: lazy(() => import("./Faturas")),
    },*/
  ],
};
